export default {
  state: {
    filter: {
      contributionMargin: false,
      toYear: new Date().getFullYear().toString(),
      fromYear: (new Date().getFullYear() - 3).toString(),
      fromMonth: 'Jänner',
      toMonth: 'Dezember',
      location: [],
      articleType: [],
      articleCategory: [],
      articleGroup: [],
    },
  },
  mutations: {
    SET_DEFAULT_FILTER_DATA(state) {
      state.filter = {
        contributionMargin: false,
        toYear: new Date().getFullYear().toString(),
        fromYear: (new Date().getFullYear() - 3).toString(),
        fromMonth: 'Jänner',
        toMonth: 'Dezember',
        location: [],
        articleType: [],
        articleCategory: [],
        articleGroup: [],
      }
    },
  },
  actions: {
    setDefaultRevenueFilterData({ commit }) {
      commit('SET_DEFAULT_FILTER_DATA')
    },
  },
}
