export default {
  state: {
    filter: {
      fromYear: new Date().getFullYear().toString(),
      documentTypes: [30],
    },
  },
  mutations: {
    SET_DEFAULT_FILTER_DATA(state) {
      state.filter = {
        fromYear: new Date().getFullYear().toString(),
        documentTypes: [30],
      }
    },
  },
  actions: {
    setDefaultDocumentFilterData({ commit }) {
      commit('SET_DEFAULT_FILTER_DATA')
    },
  },
}
