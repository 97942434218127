export default {
  state: {
    filter: {
      toYear: new Date().getFullYear().toString(),
      fromYear: (new Date().getFullYear() - 3).toString(),
      category: 'Standort',
      location: [],
      articleType: [],
      articleCategory: [],
      articleGroup: [],
      status: [],
      revenueGroup: [],
    },
    filter2: {
      toYear: new Date().getFullYear().toString(),
      fromYear: (new Date().getFullYear() - 3).toString(),
      category: 'Standort',
      location: [],
      articleType: [],
      articleCategory: [],
      articleGroup: [],
      status: [],
      revenueGroup: [],
    },
  },
  mutations: {
    SET_DEFAULT_FILTER_DATA(state) {
      state.filter = {
        toYear: new Date().getFullYear().toString(),
        fromYear: (new Date().getFullYear() - 3).toString(),
        category: 'Standort',
        location: [],
        articleType: [],
        articleCategory: [],
        articleGroup: [],
        status: [],
        revenueGroup: [],
      }
    },
    SET_DEFAULT_FILTER2_DATA(state) {
      state.filter2 = {
        toYear: new Date().getFullYear().toString(),
        fromYear: (new Date().getFullYear() - 3).toString(),
        category: 'Standort',
        location: [],
        articleType: [],
        articleCategory: [],
        articleGroup: [],
        status: [],
        revenueGroup: [],
      }
    },
  },
  actions: {
    setDefaultCategoryFilterData({ commit }) {
      commit('SET_DEFAULT_FILTER_DATA')
    },
    setDefaultCategoryFilter2Data({ commit }) {
      commit('SET_DEFAULT_FILTER2_DATA')
    },
  },
}
