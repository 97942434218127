<template>
  <v-navigation-drawer
    v-model="isNavbarOpen"
    app
    :permanent="!miniNavbar"
    :temporary="miniNavbar"
    mobile-breakpoint="md"
    class="elevation-0 d-flex flex-column"
  >
    <div>
      <v-list nav dense>
        <v-list-item
          :to="{ name: 'Home' }"
          color="white"
          class="mx-3 pa-2 my-5"
        >
          <img
            src="@/assets/LBA-Logo-Text.svg"
            alt="LBA-WEB"
            class="pa-3"
            style="max-width: 150px"
          />
        </v-list-item>

        <div
          v-for="category in appCategories"
          v-show="getAppsForCategoryId(category.id).length > 0"
          :key="category.id"
        >
          <v-divider class="mx-4"></v-divider>

          <div class="my-5 mx-3 pa-2">
            <h5 v-if="!miniNavbar" class="text-uppercase pb-2 primary--text">
              {{ category.bezeichnung }}
            </h5>
            <!-- Alle Apps anzeigen -->
            <div v-for="(app, i) in getAppsForCategoryId(category.id)" :key="i">
              <v-list-item
                v-if="app.appId != 3 && app.appId != 10"
                exact
                :to="app.appUrl"
                :disabled="!app.hasPermission"
                class="primary--text li-item"
                active-class="selected-li-item"
              >
                <v-list-item-avatar>
                  <img :src="appImg(app)" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    >{{ app.appBezeichnung }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <!-- STATISTIK ausklappbar -->
              <v-list-group v-if="app.appId == 3">
                <template #activator>
                  <v-list-item-avatar>
                    <img :src="appImg(app)" />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="primary--text"
                      >{{ app.appBezeichnung }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>

                  <v-list-item
                    v-if="statisticsSubApps.includes('WEB_Umsatzstatistik')"
                    :to="'/statistics/revenue'"
                    class="primary--text li-item pl-6"
                    active-class="selected-li-item"
                  >
                    <v-list-item-avatar>
                      <img :src="appImg(app)" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>Umsatzstatistik</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    v-if="statisticsSubApps.includes('WEB_Kundenstatistik')"
                    :to="'/statistics/customer'"
                    class="primary--text li-item pl-6"
                    active-class="selected-li-item"
                  >
                    <v-list-item-avatar>
                      <img :src="appImg(app)" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>Kundenstatistik</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    v-if="
                      statisticsSubApps.includes('WEB_Lieferantenstatistik')
                    "
                    :to="'/statistics/distributor'"
                    class="primary--text li-item pl-6"
                    active-class="selected-li-item"
                  >
                    <v-list-item-avatar>
                      <img :src="appImg(app)" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        >Lieferantenstatistik</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    v-if="statisticsSubApps.includes('WEB_Dokumentstatistik')"
                    :to="'/statistics/document'"
                    class="primary--text li-item pl-6"
                    active-class="selected-li-item"
                  >
                    <v-list-item-avatar>
                      <img :src="appImg(app)" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>Dokumentstatistik</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>

              <!-- Einstellungen ausklappbar -->
              <v-list-group
                v-if="app.appId == 10"
                color="white"
              >
                <template #activator>
                  <v-list-item-avatar>
                    <img :src="appImg(app)" />
                  </v-list-item-avatar>
                  <v-list-item-content class="primary--text">
                    <v-list-item-title
                      >{{ app.appBezeichnung }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-item
                  v-for="(appWithSettings, i) in getAppsWithSettings()"
                  :key="i"
                  exact
                  :to="'/settings' + appWithSettings.appUrl"
                  :disabled="!appWithSettings.hasPermission"
                  class="primary--text li-item pl-6"
                  active-class="selected-li-item"
                >
                  <v-list-item-avatar>
                    <img :src="appImg(appWithSettings)" />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ appWithSettings.appBezeichnung }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </div>
          </div>
        </div>

        <!--
          <div class="my-5 mb-8 mx-3 pa-2" v-if="lbaSettingsApps.length != 0">
            <h5
              v-if="!miniNavbar"
              class="text-uppercase pb-2 pl-2 primary--text"
            >
              LBA-Einstellungen
            </h5>


            <v-list-item
              v-for="(app, i) in getSettingsAppsWithoutSettings()"
              :key="i"
              exact
              :to="app.appUrl"
              :disabled="!app.hasPermission"
              class="primary--text li-item"
              active-class="selected-li-item"
            >
              <v-list-item-avatar>
                <img :src="appImg(app)" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ app.appBezeichnung }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-group v-if="settingsAppExists()" color="white">
              <template #activator>
                <v-list-item-avatar>
                  <img :src="appImg(settingsApp)" />
                </v-list-item-avatar>
                <v-list-item-content class="primary--text">
                  <v-list-item-title>
                    {{ settingsApp.appBezeichnung }}</v-list-item-title
                     >
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="(app, i) in getAppsWithSettings()"
                :key="i"
                exact
                :to="'/settings' + app.appUrl"
                :disabled="!app.hasPermission"
                class="primary--text li-item pl-6"
                active-class="selected-li-item"
              >
                <v-list-item-avatar>
                  <img :src="appImg(app)" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="app.appBezeichnung"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </div>
          -->
      </v-list>
    </div>
    <template #append>
      <v-divider class="mx-4"></v-divider>
      <v-container class="d-flex flex-column align-center my-2">
        <VersionChip />

        <!--Unternehmensname + PLZ +-->
        <v-expansion-panels class="pt-4 text-center">
          <v-expansion-panel>
            <v-expansion-panel-header class="text-center primary--text">
              <template #actions>
                <v-icon color="primary"> $expand</v-icon>
              </template>
              {{ company.name }}
            </v-expansion-panel-header>
            <v-expansion-panel-content class="primary--text">
              <div class="ma-1">
                {{ company.postcode }}
                {{ company.city }}, {{ company.country }}
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'

import statisticsLogo from '@/assets/svg/lba-apps/LBA-Statistik.svg'
//TODO: create Documents logo
import docsLogo from '@/assets/svg/lba-apps/LBA-Documents.svg'
import calLogo from '@/assets/svg/lba-apps/LBA-Kalender.svg'
import crmLogo from '@/assets/svg/lba-apps/LBA-CRM.svg'
import devLogo from '@/assets/svg/lba-apps/LBA-DEV.svg'
import artikelLogo from '@/assets/svg/lba-apps/LBA-ArticleSearch.svg'
import businessLogo from '@/assets/svg/lba-apps/LBA-Business.svg'
import taskLogo from '@/assets/svg/lba-apps/LBA-Task.svg'
import settingsLogo from '@/assets/svg/lba-apps/LBA-Settings.svg'
import VersionChip from '@/components/core/VersionChip.vue'

import { reportAppAccessById } from '@/services/core/telemetryService.js'
import { getAppCategories } from '@/services/core/appService.js'

export default {
  components: {
    VersionChip,
  },
  props: {
    drawer: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      group: null,
      homeAppString: 'LBA-WEB',
      isNavbarOpen: false,
    }
  },
  computed: {
    ...mapGetters([
      'loggedIn',
      'user',
      'user_img_base64',
      'availableApps',
      'lbaSettingsApps',
      'company',
      'availableApps',
      'appCategories',
      'statisticsSubApps',
    ]),
    miniNavbar() {
      if (
        this.$vuetify.breakpoint.name == 'xs' ||
        this.$vuetify.breakpoint.name == 'sm' ||
        this.$vuetify.breakpoint.name == 'md'
      ) {
        return true
      } else {
        return false
      }
    },
    currentApplication() {
      // checks which lba-app matches the current router path. Reporting is outsourced in a watcher below.

      if (this.$route.path <= 1) return undefined

      let routerSplitString = this.$route.path.substring(1).split('/')

      return this.availableApps.find(
        (app) => routerSplitString[0] == app.appUrl.substring(1)
      )
    },
    loginButtonVisible() {
      if (!this.loggedIn && this.$route.name != 'Login') {
        return true
      }
      return false
    },
  },
  watch: {
    drawer(newVal) {
      this.$emit('drawerChange', newVal)
      this.isNavbarOpen = newVal
    },
    isNavbarOpen(newVal) {
      if (this.drawer !== newVal) this.$emit('drawerChange', newVal)
    },
    async currentApplication(newApp, oldApp) {
      if (
        newApp != undefined &&
        JSON.stringify(newApp) != JSON.stringify(oldApp)
      ) {
        reportAppAccessById(newApp.appId)
      }
    },
  },
  async mounted() {
    this.$store.dispatch('loadUserData')
    this.$store.dispatch('loadUserImageBase64')
    this.$store.dispatch('loadUserSettings')
    this.$store.dispatch('loadVisibleTabs')
    this.$store.dispatch('loadAppsAvailableForCurrentUser')
    this.$store.dispatch('loadStatisticsSubApps')

    let appCategories = await getAppCategories()
    this.$store.dispatch('setAppCategories', appCategories)

    this.isNavbarOpen = this.drawer

    this.unwatch = this.$store.watch(
      (state, getters) => getters.loggedIn,
      (newValue) => {
        if (newValue == true) {
          this.$store.dispatch('loadUserData')
          this.$store.dispatch('loadUserImageBase64')
          this.$store.dispatch('loadUserSettings')
        } else {
          this.$store.dispatch('clearUserData')
        }
      }
    )
  },
  beforeDestroy() {
    this.unwatch()
  },
  methods: {
    isMobile() {
      if (this.$vuetify.breakpoint.name == 'sm') {
        return true
      } else {
        return false
      }
    },
    routeIncludes(search) {
      if (this.$route.matched.some((route) => route.path.includes(search))) {
        return true
      }
      return false
    },
    appImg(item) {
      if (item.appId == 3) {
        return statisticsLogo
      } else if (item.appId == 2) {
        return calLogo
      } else if (item.appId == 1) {
        return crmLogo
      } else if (item.appId == 6) {
        return devLogo
      } else if (item.appId == 7) {
        return artikelLogo
      } else if (item.appId == 8) {
        return businessLogo
      } else if (item.appId == 9) {
        return taskLogo
      } else if (item.appId == 10) {
        return settingsLogo
      } else if (item.appId == 4) {
        return docsLogo
      }
      return require('@/assets/LBA_logo_big.png')
    },
    navigateToHome() {
      this.$router.push('/')
    },
    getAppsForCategoryId(id) {
      return this.$store.getters.getAppsForCategory(id)
    },
    getAppsWithSettings() {
      return this.$store.getters.getAppsWithSettings
    },
  },
}
</script>

<style scoped>
.selected-li-item {
  border-left: 3px solid;
  margin-left: -3px;
}

.v-expansion-panel::before {
  box-shadow: none;
}
</style>
