<template>
  <LBAExpansionPanel
    :textColor="'default'"
    :titleType="'default'"
    :padding="padding"
    @click="clicked"
    ><template #title
      ><v-row
        ><v-icon class="mr-3">mdi-filter</v-icon
        ><span class="mt-1">Filter</span></v-row
      ></template
    ><template #content><slot name="content"></slot></template
    ><template #actions
      ><slot name="actions"></slot
      ><LBAButton :buttonType="'error'" :buttonStyle="'text'" @click="cleared"
        ><template #content
          ><v-icon class="mr-2">mdi-filter-off</v-icon>Filter Löschen</template
        ></LBAButton
      ></template
    ></LBAExpansionPanel
  >
</template>

<script>
export default {
  props: {
    padding: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    cleared() {
      this.$emit('clear')
    },
    clicked() {
      this.$emit('click')
    },
  },
}
</script>
