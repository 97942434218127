export default {
  state: {
    filter: {
      toYear: new Date().getFullYear().toString(),
      fromYear: (new Date().getFullYear() - 3).toString(),
    },
  },
  mutations: {
    SET_DEFAULT_FILTER_DATA(state) {
      state.filter = {
        toYear: new Date().getFullYear().toString(),
        fromYear: (new Date().getFullYear() - 3).toString(),
      }
    },
  },
  actions: {
    setDefaultCustomerFilterData({ commit }) {
      commit('SET_DEFAULT_FILTER_DATA')
    },
  },
}
