import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index.js'
import { renewBearerWithRefreshTokenAndNavigateToPath } from '@/services/core/authService.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/home/Home.vue'),
    meta: { displayName: 'Dashboard' },
  },
  {
    path: '/login',
    name: 'Login',
    meta: { noAuthRequired: true },
    component: () =>
      import(/*webpackChunkName: "login"*/ '@/views/core/Login.vue'),
  },
  {
    path: '/statistics',
    meta: { displayName: 'Statistik', linkable: false },
  },
  {
    path: '/statistics/distributors/:lfdnr/overallChart',
    name: 'ekRevenueOverallChart',
    meta: { displayName: 'EK-Statistik: Gesamt Verlauf' },
    component: () => import('@/views/statistics/ekRevenue/Overall.vue'),
  },
  {
    path: '/statistics/distributors/:lfdnr/typesChart/:articleTypeCode',
    name: 'ekRevenueTypesChart',
    meta: { displayName: 'EK-Statistik: Artikeltyp Verlauf' },
    component: () => import('@/views/statistics/ekRevenue/ArticleTypes.vue'),
  },
  {
    path: '/statistics/distributors/:lfdnr/typesChart/:articleTypeCode/categoriesChart/:articleCategoryCode',
    name: 'ekRevenueCategoriesChart',
    meta: { displayName: 'EK-Statistik: Artikelkategorie Verlauf' },
    component: () =>
      import('@/views/statistics/ekRevenue/ArticleCategories.vue'),
  },
  {
    path: '/statistics/distributors/:lfdnr/typesChart/:articleTypeCode/categoriesChart/:articleCategoryCode/articlesChart/:articleCode',
    name: 'ekRevenueArticlesChart',
    meta: { displayName: 'EK-Statistik: Artikel Verlauf' },
    component: () => import('@/views/statistics/ekRevenue/Articles.vue'),
  },
  {
    path: '/statistics/revenue',
    name: 'revenueStatistics',
    meta: { displayName: 'Umsatzstatistik' },
    component: () => import('@/views/statistics_reloaded/revenue/Revenue.vue'),
  },
  {
    path: '/statistics/customer',
    name: 'customerStatistics',
    meta: { displayName: 'Kundenstatistik' },
    component: () =>
      import('@/views/statistics_reloaded/customer/Customer.vue'),
  },
  {
    path: '/statistics/distributor',
    name: 'distributorStatistics',
    meta: { displayName: 'Lieferantenstatistik' },
    component: () =>
      import('@/views/statistics_reloaded/distributor/Distributor.vue'),
  },
  {
    path: '/statistics/document',
    name: 'documentStatistic',
    meta: { displayName: 'Dokumentstatistik' },
    component: () =>
      import('@/views/statistics_reloaded/document/Document.vue'),
  },
  {
    path: '/settings/crm',
    name: 'Einstellungen: CRM',
    component: () => import('@/views/settings/Settings.vue'),
    props: { app: 'crm', displayName: 'CRM' },
  },
  {
    path: '/settings/calv2',
    name: 'Einstellungen: Kalender',
    component: () => import('@/views/settings/Settings.vue'),
    props: { app: 'cal', displayName: 'Kalender' },
  },
  {
    path: '/settings/task',
    name: 'Einstellungen: Task',
    component: () => import('@/views/settings/Settings.vue'),
    props: { app: 'task', displayName: 'Task' },
  },
  {
    path: '/calv2',
    name: 'Calendar-v2',
    meta: { displayName: 'Kalender', version: '✨ v2' },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "calendar" */ '@/views/calendar/Calendar.vue'
      ),
    beforeEnter: (to, from, next) => {
      if (
        store.getters.settings_cal !== undefined &&
        store.getters.locations !== undefined &&
        store.getters.scopes !== undefined
      ) {
        next()
      } else {
        next({
          path: '/calv2/loading',
          query: to.query,
        })
      }
    },
  },
  {
    path: '/calv2/loading',
    name: 'Calender-v2 Loading',
    component: () =>
      import(/* webpackChunkName: "calendar" */ '@/views/calendar/Loading.vue'),
  },
  {
    path: '/bi/revenue',
    name: 'Umsatzstatistik',
    component: () =>
      import(/* webpackChunkName: "Revenue" */ '@/views/bi/Revenue.vue'),
  },
  {
    path: '/bi/keyfigures',
    name: 'Kennzahlen',
    component: () =>
      import(/* webpackChunkName: "Keyfigures" */ '@/views/bi/KeyFigures.vue'),
  },
  {
    path: '/task',
    name: 'Task',
    meta: { displayName: 'Aufgabenverwaltung' },
    component: () =>
      import(/* webpackChunkName: "Task" */ '@/views/task/Task.vue'),
  },
  {
    path: '/task/new',
    name: 'Aufgabe erstellen',
    component: () =>
      import(/* webpackChunkName: "AddTask" */ '@/views/task/AddTask.vue'),
  },
  {
    path: '/task/:lfdnr',
    name: 'TaskDetails',
    props: (route) => {
      const lfdnr = Number.parseInt(route.params.lfdnr, 10)
      if (Number.isNaN(lfdnr)) {
        return 0
      }
      return { lfdnr }
    },
    component: () => import('@/views/task/TaskDetailRedirect.vue'),
  },
  {
    name: 'Dokumente',
    path: '/documents',
    meta: {
      displayName: 'Dokumente',
    },
    component: () => import('@/views/crm/Documents.vue'),
  },
  {
    name: 'CRM',
    path: '/crm',
    meta: { displayName: 'Kundenverwaltung' },
    component: () =>
      import(/* webpackChunkName: "crm" */ '@/views/crm/CRM.vue'),
  },
  {
    path: '/crm/addresses',
    redirect: { name: 'CRM' },
    meta: { displayName: 'Adressen' },
  },
  {
    path: '/crm/addresses/new',
    name: 'crmNewAddress',
    meta: { displayName: 'Neue Adresse' },
    component: () => import('@/views/crm/addresses/AddAddress.vue'),
  },
  {
    path: '/crm/addresses/:lfdnr/:tab?',
    name: 'crmAddress',
    meta: { displayName: 'Adressen' },
    props: (route) => {
      const lfdnr = Number.parseInt(route.params.lfdnr, 10)
      if (Number.isNaN(lfdnr)) {
        return 0
      }
      return { lfdnr, tab: route.params.tab || null }
    },
    component: () =>
      import(
        /* webpackChunkName: "crmAddress" */ '@/views/crm/addresses/Address.vue'
      ),
  },
  {
    path: '/crm/addresses/:lfdnr/documents/:wf_cid',
    name: 'crmAddressDocumentDetailed',
    meta: { displayName: 'Dokument' },
    props: (route) => {
      const wf_cid = route.params.wf_cid
      const lfdnr = Number.parseInt(route.params.lfdnr, 10)
      if (Number.isNaN(lfdnr)) {
        return 0
      }
      return { lfdnr, wf_cid }
    },
    component: () =>
      import(
        /* webpackChunkName: "crmAddressDocumentDetailed" */ '@/views/crm/addresses/AddressDocumentDetailedRedirect.vue'
      ),
  },

  {
    path: '/dev',
    name: 'DEV-Portal',
    meta: { displayName: 'Entwickler Portal' },

    component: () =>
      import(
        /* webpackChunkName: "devPortal" */ '@/views/devPortal/DevPortal.vue'
      ),
  },
  {
    path: '/articles',
    name: 'Artikel',
    meta: {
      displayName: 'Artikel',
    },
    component: () =>
      import(
        /* webpackChunkName: "Artikelsuche" */ '@/views/article/Article.vue'
      ),
  },
  {
    path: '/articles/:articleNumber/:tab',
    name: 'Artikeldetails',
    props: (route) => {
      const articleNumber = route.params.articleNumber

      return { articleNumber }
    },
    component: () =>
      import(
        /* webpackChunkName: "Artikel" */ '@/views/article/ArticleView.vue'
      ),
  },
  {
    path: '/business',
    name: 'Business',
    meta: {
      displayName: 'Business',
    },
    component: () =>
      import(
        /* webPackChunkName: "Business" */ '@/views/business/Business.vue'
      ),
  },
  {
    path: '/notifications',
    name: 'Notification',
    meta: { displayName: 'Benachrichtigungen' },
    component: () =>
      import(
        /* webpackChunkName: "" */ '@/views/notifications/NotificationView.vue'
      ),
  },
  {
    path: '/demo/qrcode',
    name: 'QRCode',
    meta: { displayName: 'QRCode' },
    // check for dev mode
    beforeEnter: (to, from, next) => {
      if (process.env.NODE_ENV == 'development') next()
    },
    component: () =>
      import(/* webpackChunkName: "" */ '@/views/dev/LBAQrCodeReader.vue'),
  },
  {
    path: '/nopermission',
    name: 'Kein Zugriff',
    meta: { displayName: 'Kein Zugriff', noAuthRequired: true },

    component: () => import('@/views/core/NoPerm.vue'),
  },
  {
    path: '*',
    name: 'Not Found',
    meta: { displayName: 'Nicht gefunden', noAuthRequired: true },

    component: () => import('@/views/core/404.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  // Lädt refreshToken aus der URL
  // Dies ist notwendig um WEB von REV zu starten
  if (to.query.refreshToken) {
    console.debug(to)

    await renewBearerWithRefreshTokenAndNavigateToPath(
      to.query.refreshToken,
      to.path,
      to.query
    )
  }
  const loggedIn = store.getters.loggedIn

  if (!to.matched[0].meta.noAuthRequired && !loggedIn) {
    next({ path: '/login', query: { redirectTo: to.path } })
  } else {
    next()
  }
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      // resolve err
      return err
    }
    // rethrow error
    return Promise.reject(err)
  })
}

export default router
