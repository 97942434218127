import axios from 'axios'

export async function getCompany() {
  const response = await axios.get('/v1/company')

  return response.data
}

/* User */
export async function getUser() {
  const response = await axios.get('/v1/user')

  return response.data
}

export async function getUserPicture() {
  const response = await axios.get('/v1/user/picture')

  return response.data
}

export async function getUserSettings() {
  const response = await axios.get('/v1/user/settings')

  return response.data
}

/* Apps */

export async function getApps() {
  const response = await axios.get('/v1/apps')

  return response.data
}

export async function getAppCategories() {
  const response = await axios.get('/v1/categories')

  if (response.status != 200) {
    throw axios.AxiosError.ERR_BAD_REQUEST
  } else {
    return response.data
  }
}

export async function getStatisticsSubApps() {
  const response = await axios.get('/v1/statistics/subApps')

  return response.data
}

/* Notifications */
export async function getNotifications() {
  const response = await axios.get('/v1/notifications')

  return response.data
}

export async function changeNotificationStatus(notificationId) {
  const response = await axios.patch(`/v1/notifications/${notificationId}/read`)

  return response.data
}
