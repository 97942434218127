<template>
  <v-menu
    ref="dateTimeMenu"
    v-model="dateTimeMenu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template #activator="{ attrs }">
      <v-text-field
        v-if="iconPosition == 'right'"
        v-model="dateFormatted"
        :dense="dense"
        outlined
        background-color="white"
        :label="label"
        append-icon="mdi-calendar"
        :disabled="disabled"
        :rules="ruleSet"
        v-bind="attrs"
        @click:append="dateTimeMenu = true"
        @blur=";(date = parseDate(dateFormatted)), datePicked()"
      ></v-text-field>
      <v-text-field
        v-else-if="iconPosition == 'left'"
        v-model="dateFormatted"
        :dense="dense"
        outlined
        background-color="white"
        :label="label"
        prepend-icon="mdi-calendar"
        :disabled="disabled"
        :rules="ruleSet"
        v-bind="attrs"
        @click:prepend="dateTimeMenu = true"
        @blur=";(date = parseDate(dateFormatted)), datePicked()"
      ></v-text-field>
      <v-text-field
        v-else
        v-model="dateFormatted"
        :dense="dense"
        outlined
        background-color="white"
        :disabled="disabled"
        :label="label"
        :rules="ruleSet"
        v-bind="attrs"
        @click="dateTimeMenu = true"
        @blur=";(date = parseDate(dateFormatted)), datePicked()"
      ></v-text-field>
    </template>
    <v-card>
      <v-card-text>
        <v-tabs
          v-if="
            date == null ||
            date.date == '' ||
            date.date == null ||
            date.time == '' ||
            date.time == null
          "
          v-model="tab"
          fixed-tabs
          icons-and-text
        >
          <v-tab key="date" default><v-icon>mdi-calendar-range</v-icon> </v-tab>
          <v-tab key="time" :disabled="allDay">
            <v-icon>mdi-clock</v-icon></v-tab
          >
        </v-tabs>

        <v-tabs v-else v-model="tab" fixed-tabs icons-and-text>
          <v-tab key="date" default
            >{{ formatDate(date).split(' ')[0]
            }}<v-icon>mdi-calendar-range</v-icon>
          </v-tab>
          <v-tab key="time" :disabled="allDay"
            >{{ formatDate(date).split(' ')[1] }}
            <v-icon>mdi-clock</v-icon></v-tab
          >
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item key="date">
            <v-date-picker
              v-model="date.date"
              no-title
              scrollable
              first-day-of-week="1"
              color="primary"
              class="mt-3"
              :min="minimumDate"
              :max="maximumDate"
              @change="goToTime(), emitChange()"
            >
            </v-date-picker
          ></v-tab-item>
          <v-tab-item key="time">
            <v-row align="center" justify="center">
              <v-col>
                <v-text-field
                  v-model="date.time"
                  maxlength="5"
                  type="time"
                  step="300"
                  style="width: 55px"
                  class="text-justify text-center"
                  @change="roundToNearestFiveMinutes('up')"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-time-picker
              v-model="date.time"
              :allowed-minutes="allowedStep"
              :active-picker.sync="activePicker"
              :min="minimumTime"
              :max="maximumTime"
              format="24hr"
              view="minute"
              no-title
              color="primary"
              @click:minute="switchToHourView"
              @change="emitChange()"
            ></v-time-picker>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="dateTimeMenu = false">
          Abbrechen
        </v-btn>
        <v-btn
          text
          color="primary"
          @click="$refs.dateTimeMenu.save(date), datePicked()"
        >
          OK
        </v-btn></v-card-actions
      >
    </v-card>
  </v-menu>
</template>
<script>
import {
  isValidDate,
  isValidTime,
} from '@/services/calendar/dateTimeService.js'
export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: 'Datum und Uhrzeit wählen',
    },
    dense: {
      type: Boolean,
      default: false,
    },
    //TODO: add dateFormat property
    iconPosition: {
      type: String,
      default: 'right',
    },
    minimumDate: {
      type: [String, Date],
      default: null,
    },
    maximumDate: {
      type: [String, Date],
      default: null,
    },
    minimumTime: {
      type: String,
      default: '00:00',
    },
    maximumTime: {
      type: String,
      default: '23:59',
    },
    allDay: {
      type: Boolean,
      default: false,
    },
    defaultTime: {
      type: String,
      default: '08:00',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedDate: '',
      date: {
        date: '',
        time: '',
      },
      activePicker: '',
      dateFormatted: null,
      dateTimeMenu: false,
      defTime: {
        hour: '13',
        minute: '25',
      },
      tab: 0,
      allowedStep: (m) => m % 5 === 0,
    }
  },
  computed: {
    ruleSet() {
      // Combine the legalDate rule with the custom rules provided via the :rules prop
      return [this.legalDate, ...this.rules]
    },
  },
  watch: {
    value(newValue) {
      this.date = newValue // Update selectedDate when the value prop changes
    },
    date: {
      handler(newVal) {
        this.dateFormatted = this.formatDate(newVal)
      },
      deep: true,
    },
  },
  mounted() {
    this.date = this.value
    if (this.allDay) {
      this.date.time = '--:--'
    }
    this.tab = 0
    const [hour, minute] = this.defaultTime.split(':')
    this.defTime.hour = hour
    this.defTime.minute = minute
  },
  methods: {
    switchToHourView() {
      this.activePicker = 'HOUR'
    },
    datePicked() {
      const valid = this.ruleSet.every(
        (rule) => rule(this.formatDate(this.date)) === true
      )

      if (!valid) return

      if (this.allDay) {
        this.$emit('datePicked', { date: this.date.date, time: '--:--' })
      } else {
        this.$emit('datePicked', this.date)
      }
    },
    goToTime() {
      if (!this.allDay) this.tab = 1
    },
    parseDate(date) {
      if (!date) return { date: '', time: '' }
      if (date.includes('.')) {
        return this.parseDottedDate(date)
      } else {
        return this.parseShortDate(date)
      }
    },
    parseDottedDate(dateTime) {
      //e.g.: 13.01.1990
      let [date, time] = dateTime.split(' ')

      //if no time is set --> set to 08:00 by default
      if (time == undefined) {
        time = this.defTime.hour + ':' + this.defTime.minute
      }
      const [day, month, year] = date.split('.')
      let [hour, minute] = time.split(':')

      //if no time is set make it 08:00 by default
      if (hour == '') {
        hour = this.defTime.hour
      }

      if (minute == '') {
        minute = this.defTime.minute
      }

      return {
        date: `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`,
        time: hour + ':' + minute,
      }
    },
    parseShortDate(date) {
      //e.g.: 13011990
      const day = date.slice(0, 2)
      const month = date.slice(2, 4)
      const year = date.slice(4, 8)

      let hour = date.slice(9, 11)
      let minute = date.slice(11, 13)

      //if no time is set make it 08:00 by default
      if (hour == '') {
        hour = this.defTime.hour
      }

      if (minute == '') {
        minute = this.defTime.minute
      }

      return {
        date: `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`,
        time: hour + ':' + minute,
      }
    },
    formatDate(dateTime) {
      if (!dateTime) return ''
      if (dateTime.date == '') return ''
      if (dateTime.time == '') return ''

      const [year, month, day] = dateTime.date.split('-')
      const [hour, minute] = dateTime.time.split(':')

      if (!this.allDay) return `${day}.${month}.${year} ${hour}:${minute}`
      else return `${day}.${month}.${year} (ganztägig)`
    },
    legalDate(value) {
      if (value != null || value != undefined) value = this.parseDate(value)

      if (value == null) {
        return 'Kein Datum angegeben!'
      }

      if (value === null || value === undefined) {
        return 'Kein gültiger Input!'
      }

      if (value.date === null || value.date === undefined) {
        return 'Kein gültiges Datum!'
      }

      if (value.time === null || value.time === undefined) {
        return 'Keine gültige Zeit!'
      }

      const [year, month, day] = value.date.split('-')
      const [hour, minute] = value.time.split(':')

      if (value.date == '' && value.time == '') {
        return true
      }

      return (
        (isValidDate(day, month, year) &&
          isValidTime(hour, minute) &&
          this.checkTimeWithinConstraints(hour, minute)) ||
        hour == '(ganztägig)' ||
        'Kein gültiges Datum!'
      )
    },
    checkTimeWithinConstraints(hour, minute) {
      const [minHour, minMinute] = this.minimumTime.split(':').map(Number)
      const [maxHour, maxMinute] = this.maximumTime.split(':').map(Number)

      // Convert the given time, minTime, and maxTime into Date objects
      const givenTime = new Date(0, 0, 0, hour, minute)
      const minTimeObj = new Date(0, 0, 0, minHour, minMinute)
      const maxTimeObj = new Date(0, 0, 0, maxHour, maxMinute)

      // Check if the given time is within the range
      return givenTime >= minTimeObj && givenTime <= maxTimeObj
    },
    roundToNearestFiveMinutes(roundingMode = 'up') {
      let timeString = this.date.time

      const [hours, minutes] = timeString.split(':').map(Number)
      const remainder = minutes % 5

      // If remainder is 0, the time is already a multiple of 5, so return it as is
      if (remainder === 0) {
        return timeString
      }

      // Calculate the minutes to add or subtract to reach the nearest multiple of 5
      let minutesAdjustment = 0
      if (roundingMode === 'up') {
        minutesAdjustment = 5 - remainder
      } else if (roundingMode === 'down') {
        minutesAdjustment = -remainder
      }

      // Calculate the rounded time
      const roundedMinutes = (minutes + minutesAdjustment + 60) % 60
      const roundedHours =
        (hours + Math.floor((minutes + minutesAdjustment) / 60) + 24) % 24

      // Pad the hours and minutes with leading zeros if necessary
      const roundedHoursString = String(roundedHours).padStart(2, '0')
      const roundedMinutesString = String(roundedMinutes).padStart(2, '0')

      // Return the rounded time in HH:mm format
      this.date.time = `${roundedHoursString}:${roundedMinutesString}`
    },
    emitChange() {
      const valid = this.ruleSet.every(
        (rule) => rule(this.formatDate(this.date)) === true
      )

      if (!valid) return

      this.$emit('input', this.date)
    },
  },
}
</script>
